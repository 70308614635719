import CerialWebPage from './main/CerialWebPage';
import './componentes/App.css';
import React from 'react';


function App() {
  return (
    <div className="App">
      <CerialWebPage></CerialWebPage>
    </div>
  );
}

export default App;
