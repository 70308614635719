import { Button, ButtonGroup, Card, Container, ToggleButton } from 'react-bootstrap'
import Banner from '../componentes/BandWebPage/Banner'
import React, { useState } from 'react';
import cerial from '../Photos/bands/CQ1.png'
import cerial2 from '../Photos/bands/CQ2.png'
import cerial3 from '../Photos/bands/CQ3.png'
import spotify from '../componentes/BandWebPage/Spotify';
import IconLinks from '../componentes/BandWebPage/IconLinks';
import youtube from '../componentes/BandWebPage/Youtube';
import './CerialWebPage.css'
import DisplayShows from '../componentes/Shows/DisplayShows';
import Galeria from '../componentes/BandWebPage/Galeria';
import { SiApplemusic, SiTidal, SiYoutubemusic } from 'react-icons/si';
import { BiLogoDeezer } from "react-icons/bi";




export default function CerialWebPage() {
    const [radioValue, setRadioValue] = useState('1');
    const radios = [
        { name: 'Quem Somos?', value: '1' },
        { name: 'Calendário', value: '2' },
    ];

    function selectDisplay() {
        if (radioValue == 1) return info()
        else return <DisplayShows />
    }

    return (<>
        {Banner(cerial, cerial2, cerial3)}
        <Container>
            <h1>Cerial Quiller</h1>
            <IconLinks></IconLinks>
            <ButtonGroup className='pt-3'>
                {radios.map((radio, idx) => (
                    <ToggleButton
                        key={idx}
                        id={`radio-${idx}`}
                        type="radio"
                        name="radio"
                        value={radio.value}
                        checked={radioValue === radio.value}
                        onChange={(e) => setRadioValue(e.currentTarget.value)}
                    >
                        {radio.name}
                    </ToggleButton>
                ))}
            </ButtonGroup>
            {selectDisplay()}
        </Container>
    </>)

}
function info() {

    var iconsize = 26;
    return (<>
        <h1>Quem somos?</h1>
        <Paragraph text={"Os Cerial Quiller são uma banda de Rock oriunda de Aveiro, mais precisamente fundada em Avanca, uma pequena vila cheia de milho e vacarias, a fábrica da Nestlé e a Casa Museu Egas Moniz, vencedor do prémio Nobel da medicina e criador da Lobotomia, por muitos conhecido como um louco. Daí surge o nome, Cerial Quiller, uma junção das melhores coisas que Avanca tem para oferecer.São uma banda de originais e contam com um EP lançado, “Olá Jorge” onde viajam por vários estilos musicais, porém com maior foco no Hard Rock. Com uma performance cheia de energia ao som do punk, rock, metal e até mesmo jazz. Os Cerial prometem um concerto versátil e cheio de animação."}></Paragraph>
        <h1 className='pb-2'>Ultimo Lançamento</h1>
        {spotify('https://open.spotify.com/embed/album/5hTJvHsKMidZAYwJeoAZwX?utm_source=generator')}
        <div className='mt-3'>
            <a href='https://music.apple.com/us/album/vinte-single/1747250067' target="_blank" id="icon-band">
                <SiApplemusic size={iconsize} />
            </a>
            <a href='https://music.youtube.com/watch?v=saoQRJc8BhI&si=yGnQjC0--0HMLzYB' target="_blank" id="icon-band">
                <SiYoutubemusic size={iconsize} />
            </a>
            <a href='https://listen.tidal.com/album/366404873/track/366404874' target="_blank" id="icon-band">
                <SiTidal size={iconsize} />
            </a>
            <a href='https://www.deezer.com/pt/track/2807873482' target="_blank" id="icon-band">
                <BiLogoDeezer size={iconsize} />
            </a>
        </div>
        <iframe width={'80%'} src="https://www.youtube.com/embed/qEOxKZKq0XQ?si=HH43y3gUSo251swq" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen className='pt-3'></iframe>

        {
        //youtube()
        }
        <div className="d-grid gap-2 pt-3 pb-3" >
            <Button size='lg' onClick={() => { window.location = "mailto:cerialquillercontacto@gmail.com" }}>Enviar Email</Button>
        </div>
        {Galeria()}
    </>)
}

const Paragraph = ({ text }) => {
    const [expanded, setExpanded] = useState(false);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    return (
        <p className={expanded ? "full-text" : "max-lines"} onClick={toggleExpanded}>
            {text}
        </p>
    );
};