import React from 'react';
import { FaTiktok } from 'react-icons/fa';
import { BsInstagram, BsSpotify, BsYoutube } from 'react-icons/bs';
import { SiApplemusic, SiYoutubemusic } from "react-icons/si";


export default function IconLinks() {
    var iconsize = 36;
    return (<div className='mt-3'>
        <a href="https://open.spotify.com/artist/2vmn5ZNwvELhiAdxVpu3BH" target="_blank" id="icon-band">
            <BsSpotify size={iconsize}></BsSpotify>
        </a>
        <a href='https://music.apple.com/us/artist/cerial-quiller/1558516886' target="_blank" id="icon-band">
            <SiApplemusic size={iconsize} />
        </a>
        <a href='https://music.youtube.com/channel/UC2cjBVpOhMV-xr8eW8nRV2Q?si=QpVFICS7EqGEoSXD' target="_blank" id="icon-band">
            <SiYoutubemusic size={iconsize} />
        </a>
        <a className='link' href='https://www.instagram.com/cerial_quiller/' target='_blank' id="icon-band">
            <BsInstagram size={iconsize}></BsInstagram>
        </a>
        <a href='https://www.youtube.com/@cerial_quiller' target="_blank" id="icon-band">
            <BsYoutube size={iconsize} />
        </a>
        <a href='https://www.tiktok.com/@cerialquiller' target="_blank" id="icon-band">
            <FaTiktok size={iconsize} />
        </a>

    </div>)
}