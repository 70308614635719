import React, { useState } from "react"
import { CardBody, Container, Card, Button, Table } from "react-bootstrap"
import cartaz from "./cartazBuraco.jpeg"
import "../App.css"
import { Dialog, DialogContent } from "@mui/material";

export default function DisplayShows() {
   
    let events2024 = [
        {
            evento: "Benji Fest",
            local: "Pardilhó",
            data: "25 Maio",
            label: "Info",
            link:"https://fb.me/e/3Zp0GIc6I"
        },
        {
            evento: "Uncle Joe's",
            local: "Esmoriz",
            data: "4 Maio",
        },
        {
            evento: "Maus Hábitos",
            local: "Vila Real",
            data: "19 Abril",
        },
        {
            evento: "Azulejo",
            local: "Ovar",
            data: "29 Março",
            link: "https://thelastseat.com/rockpascoal"
        },
        {
            evento: "Átona Fest",
            local: "Lisboa",
            data: "15 Março",
        },
        {
            evento: "Buraco",
            local: "Ovar",
            data: "20 Janeiro",
        }]

        let events2023 = [
            {
                evento: "Avanca Gare - Azulejo",
                local: "Ovar",
                data: "8 Dezembro",
            },
            {
                evento: "NAV",
                local: "Lisboa",
                data: "1 Dezembro",
            },
            {
                evento: "Titanic",
                local: "Lisboa",
                data: "30 Novembro",
            },
            {
                evento: "Avanca Gare",
                local: "Avanca",
                data: "26 Agosto",
            }]

    return <>
        <Container>
            <h1 className="pb-3">Eventos</h1>
            <p className="pb-3">
                É com muito carinho que apresentamos aqui o nosso calendário.
                Queres ver os Cerial ao vivo? Aparece num dos nosso concertos.
            </p>
            {EventTable(events2024,2024)}
            {EventTable(events2023,2023)}
        </Container>
    </>
}

function EventTable(events,ano) {
    return (
        <Table className="mb-4" variant="dark"  striped borderless>
            <thead>
                <tr>
                    <th colSpan={4}>{ano}</th>
                </tr>
            </thead>
            <tbody>
                {events.map((event, index) => (
                    <tr key={index}>
                        <td>{event.data}</td>
                        <td>{event.evento}</td>
                        <td>{event.local}</td>
                        <td>
                            {event.link && (
                                <Button variant="primary" href={event.link} target="_blank">
                                    {event.label && event.label || "Bilhetes"}
                                </Button>
                            )}
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}

function Concert(event) {
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageClick = (image) => {
        setSelectedImage(image);
    };

    const handleClose = () => {
        setSelectedImage(null);
    };
    return (<>
        <Dialog open={selectedImage !== null} onClose={handleClose} >
            <DialogContent style={{
                padding: '0px',
                overflow: 'hidden'
            }} >
                {selectedImage && (
                    <img src={selectedImage} style={{ width: '100%' }} />
                )}
            </DialogContent>
        </Dialog>

        <Card style={{ width: 'auto' }} className="mb-3">
            <Card.Img variant="top" src={cartaz} height={200} style={{ objectFit: 'cover' }} onClick={() => handleImageClick(cartaz)} />
            <Card.Body >
                <Card.Title>{event.title}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">{event.time} {event.date}</Card.Subtitle>
                <Card.Text>{event.description}</Card.Text>
                <Button href={event.location.link}>Localização</Button>
            </Card.Body>
            <Card.Footer className="text-muted">{event.location.name}</Card.Footer>
        </Card>
    </>

    )
}