import React from 'react';

import i1 from './Galeria/CQESMORIZ_01.png'
import i2 from './Galeria/CQESMORIZ_02.png'
import i3 from './Galeria/CQMAUSHABITOS01.png'
import i4 from './Galeria/cqsitev11.jpg'
import i5 from './Galeria/CQESMORIZ_03.png'
import i7 from './Galeria/CQ_2903_10.png'
import i8 from './Galeria/CQ_2903_27.png'
import i9 from './Galeria/DSC03960.jpg'
import i10 from './Galeria/CQMAUSHABITOS02.png'
import i11 from './Galeria/CQMAUSHABITOS03.png'
import i12 from './Galeria/CQMAUSHABITOS04.png'
import i13 from './Galeria/CQMAUSHABITOS05.png'

import { Stack } from 'react-bootstrap';

import './Galeria.css'


export default function Galeria() {

    const itemData = [
        {
            img1:i5,
            img2:i4,

        },
        {
            img1:i13,
            img2:i11,
        },
        {
            img1:i8,
            img2:i1,
        },
        {
            img1:i7,
            img2:i9,
        },
        {
            img1:i2,
            img2:i10,
        },
        {
            img1:i12,
            img2:i3,
        }
    ];


    return (
    <div className='pb-3'>
        <Stack direction="horizontal" gap={0} className='myStack' >
            {itemData.map((item) => (
                <div>
                    <img src={item.img1}></img>
                    <img src={item.img2}></img>
                </div>
            ))}
        </Stack>
    </div>
    );

}

