import { Carousel } from 'react-bootstrap';
import React from 'react';

export default function Banner(bannerPhoto1,bannerPhoto2,bannerPhoto3) {

    return (<>
        <Carousel indicators={false} controls={false}>
            <Carousel.Item>
                <div id="banner_photo" style={{ backgroundImage: `url(${bannerPhoto1})`, backgroundPosition: '0% 48%' }}>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div id="banner_photo" style={{ backgroundImage: `url(${bannerPhoto2})`, backgroundPosition: '0% 38%' }}>
                </div>

            </Carousel.Item>
            <Carousel.Item>
                <div id="banner_photo" style={{ backgroundImage: `url(${bannerPhoto3})`, backgroundPosition: '0% 25%' }}>
                </div>

            </Carousel.Item>
        </Carousel>
    </>

    );
}